define('tm-common/models/hyperlink', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo,
        hasMany = _emberData.default.hasMany,
        Model = _emberData.default.Model;


    var number = attr('number');
    var estdate = attr('estdate');
    var string = attr('string');

    exports.default = Model.extend({
        resourceId: number,
        display: string,
        hyperlink: string,
        type: string,
        activityLevel: string,
        weight: number,
        deactivateLink: number,
        privateFriendsList: number,
        requestScrape: number,
        automaticScrape: number,
        isUrgent: number,
        isForensic: number,
        createdOn: estdate,
        updatedOn: estdate,
        viewable: number,
        doNotUse: number,
        scrapeNotes: string,
        dueDate: string,
        writeUpdatedColumns: number,

        matter: belongsTo('matter', { async: true }),
        subject: belongsTo('subject', { async: true }),
        createdBy: belongsTo('user', { async: true }),
        updatedBy: belongsTo('user', { async: true }),
        metrics: hasMany('hyperlink-metric', { async: true }),
        posts: hasMany('posts', { async: true }),

        isPrivate: Ember.computed('viewable', {
            get: function get() {
                return this.get('viewable') === 0;
            },
            set: function set(key, value) {
                this.set('viewable', value ? 0 : 1);

                return value;
            }
        }),
        isFriendsListPrivate: Ember.computed('privateFriendsList', {
            get: function get() {
                return this.get('privateFriendsList') === 1;
            },
            set: function set(key, value) {
                this.set('privateFriendsList', value ? 1 : 0);

                return value;
            }
        }),
        isLinkDeactivated: Ember.computed('deactivateLink', {
            get: function get() {
                return this.get('deactivateLink') === 1;
            },
            set: function set(key, value) {
                this.set('deactivateLink', value ? 1 : 0);

                return value;
            }
        }),
        isAutomatic: Ember.computed('automaticScrape', {
            get: function get() {
                return this.get('automaticScrape') === 1;
            },
            set: function set(key, value) {
                this.set('automaticScrape', value ? 1 : 0);

                return value;
            }
        }),
        typeHasFollowSystem: Ember.computed('type', function () {
            return ['X-Twitter', 'Instagram', 'Pinterest'].includes(this.get('type'));
        }),

        numOfFriends: Ember.computed('metrics', {
            get: function get() {
                var correspondingMetrics = this.get('metrics').filterBy('key', 'FRIENDS');

                return correspondingMetrics.length ? correspondingMetrics[0].get('value') : undefined;
            },
            set: function set(key, value) {
                var correspondingMetrics = this.get('metrics').filterBy('key', 'FRIENDS');

                if (correspondingMetrics.length === 0) {
                    var newCorrespondingMetric = this.store.createRecord('hyperlink-metric', {
                        key: 'FRIENDS',
                        weight: 1,
                        value: value,
                        resource: this
                    });

                    this.get('metrics').pushObject(newCorrespondingMetric);
                } else {
                    var correspondingMetric = correspondingMetrics.get('firstObject');

                    correspondingMetric.set('value', value);
                }

                return value;
            }
        }),
        numOfConnections: Ember.computed('metrics', {
            get: function get() {
                var correspondingMetrics = this.get('metrics').filterBy('key', 'CONNECTIONS');

                return correspondingMetrics.length ? correspondingMetrics[0].get('value') : undefined;
            },
            set: function set(key, value) {
                var correspondingMetrics = this.get('metrics').filterBy('key', 'CONNECTIONS');

                if (correspondingMetrics.length === 0) {
                    var newCorrespondingMetric = this.store.createRecord('hyperlink-metric', {
                        key: 'CONNECTIONS',
                        weight: 1,
                        value: value,
                        resource: this
                    });

                    this.get('metrics').pushObject(newCorrespondingMetric);
                } else {
                    var correspondingMetric = correspondingMetrics.get('firstObject');

                    correspondingMetric.set('value', value);
                }

                return value;
            }
        }),
        numOfPins: Ember.computed('metrics', {
            get: function get() {
                var correspondingMetrics = this.get('metrics').filterBy('key', 'PINS');

                return correspondingMetrics.length ? correspondingMetrics[0].get('value') : undefined;
            },
            set: function set(key, value) {
                var correspondingMetrics = this.get('metrics').filterBy('key', 'PINS');

                if (correspondingMetrics.length === 0) {
                    var newCorrespondingMetric = this.store.createRecord('hyperlink-metric', {
                        key: 'PINS',
                        weight: 1,
                        value: value,
                        resource: this
                    });

                    this.get('metrics').pushObject(newCorrespondingMetric);
                } else {
                    var correspondingMetric = correspondingMetrics.get('firstObject');

                    correspondingMetric.set('value', value);
                }

                return value;
            }
        }),
        numOfPosts: Ember.computed('metrics', {
            get: function get() {
                var correspondingMetrics = this.get('metrics').filterBy('key', 'POSTS');

                return correspondingMetrics.length ? correspondingMetrics[0].get('value') : undefined;
            },
            set: function set(key, value) {
                var correspondingMetrics = this.get('metrics').filterBy('key', 'POSTS');

                if (correspondingMetrics.length === 0) {
                    var newCorrespondingMetric = this.store.createRecord('hyperlink-metric', {
                        key: 'POSTS',
                        weight: 1,
                        value: value,
                        resource: this
                    });

                    this.get('metrics').pushObject(newCorrespondingMetric);
                } else {
                    var correspondingMetric = correspondingMetrics.get('firstObject');

                    correspondingMetric.set('value', value);
                }

                return value;
            }
        }),
        numOfFollowers: Ember.computed('metrics', {
            get: function get() {
                var correspondingMetrics = this.get('metrics').filterBy('key', 'FOLLOWERS');

                return correspondingMetrics.length ? correspondingMetrics[0].get('value') : undefined;
            },
            set: function set(key, value) {
                var correspondingMetrics = this.get('metrics').filterBy('key', 'FOLLOWERS');

                if (correspondingMetrics.length === 0) {
                    var weight = ['X-Twitter', 'TikTok'].includes(this.get('type')) ? 3 : 2;

                    var newCorrespondingMetric = this.store.createRecord('hyperlink-metric', {
                        key: 'FOLLOWERS',
                        weight: weight,
                        value: value,
                        resource: this
                    });

                    this.get('metrics').pushObject(newCorrespondingMetric);
                } else {
                    var correspondingMetric = correspondingMetrics.get('firstObject');

                    correspondingMetric.set('value', value);
                }

                return value;
            }
        }),
        numOfFollowing: Ember.computed('metrics', {
            get: function get() {
                var correspondingMetrics = this.get('metrics').filterBy('key', 'FOLLOWING');

                return correspondingMetrics.length ? correspondingMetrics[0].get('value') : undefined;
            },
            set: function set(key, value) {
                var correspondingMetrics = this.get('metrics').filterBy('key', 'FOLLOWING');

                if (correspondingMetrics.length === 0) {
                    var weight = ['X-Twitter', 'TikTok'].includes(this.get('type')) ? 2 : 3;

                    var newCorrespondingMetric = this.store.createRecord('hyperlink-metric', {
                        key: 'FOLLOWING',
                        weight: weight,
                        value: value,
                        resource: this
                    });

                    this.get('metrics').pushObject(newCorrespondingMetric);
                } else {
                    var correspondingMetric = correspondingMetrics.get('firstObject');

                    correspondingMetric.set('value', value);
                }

                return value;
            }
        }),
        numOfLikes: Ember.computed('metrics', {
            get: function get() {
                var correspondingMetrics = this.get('metrics').filterBy('key', 'LIKES');

                return correspondingMetrics.length ? correspondingMetrics[0].get('value') : undefined;
            },
            set: function set(key, value) {
                var correspondingMetrics = this.get('metrics').filterBy('key', 'LIKES');

                if (correspondingMetrics.length === 0) {
                    var newCorrespondingMetric = this.store.createRecord('hyperlink-metric', {
                        key: 'LIKES',
                        weight: 4,
                        value: value,
                        resource: this
                    });

                    this.get('metrics').pushObject(newCorrespondingMetric);
                } else {
                    var correspondingMetric = correspondingMetrics.get('firstObject');

                    correspondingMetric.set('value', value);
                }

                return value;
            }
        }),
        numOfSubscribers: Ember.computed('metrics', {
            get: function get() {
                var correspondingMetrics = this.get('metrics').filterBy('key', 'SUBSCRIBERS');

                return correspondingMetrics.length ? correspondingMetrics[0].get('value') : undefined;
            },
            set: function set(key, value) {
                var correspondingMetrics = this.get('metrics').filterBy('key', 'SUBSCRIBERS');

                if (correspondingMetrics.length === 0) {
                    var newCorrespondingMetric = this.store.createRecord('hyperlink-metric', {
                        key: 'SUBSCRIBERS',
                        weight: 1,
                        value: value,
                        resource: this
                    });

                    this.get('metrics').pushObject(newCorrespondingMetric);
                } else {
                    var correspondingMetric = correspondingMetrics.get('firstObject');

                    correspondingMetric.set('value', value);
                }

                return value;
            }
        }),
        numOfViews: Ember.computed('metrics', {
            get: function get() {
                var correspondingMetrics = this.get('metrics').filterBy('key', 'VIEWS');

                return correspondingMetrics.length ? correspondingMetrics[0].get('value') : undefined;
            },
            set: function set(key, value) {
                var correspondingMetrics = this.get('metrics').filterBy('key', 'VIEWS');

                if (correspondingMetrics.length === 0) {
                    var newCorrespondingMetric = this.store.createRecord('hyperlink-metric', {
                        key: 'VIEWS',
                        weight: 2,
                        value: value,
                        resource: this
                    });

                    this.get('metrics').pushObject(newCorrespondingMetric);
                } else {
                    var correspondingMetric = correspondingMetrics.get('firstObject');

                    correspondingMetric.set('value', value);
                }

                return value;
            }
        }),
        notes: Ember.computed('metrics', {
            get: function get() {
                var correspondingMetrics = this.get('metrics').filterBy('key', 'SM_NOTES');

                return correspondingMetrics.length ? correspondingMetrics[0].get('value') : undefined;
            },
            set: function set(key, value) {
                var correspondingMetrics = this.get('metrics').filterBy('key', 'SM_NOTES');

                if (correspondingMetrics.length === 0) {
                    var newCorrespondingMetric = this.store.createRecord('hyperlink-metric', {
                        key: 'SM_NOTES',
                        weight: 5,
                        value: value,
                        resource: this
                    });

                    this.get('metrics').pushObject(newCorrespondingMetric);
                } else {
                    var correspondingMetric = correspondingMetrics.get('firstObject');

                    correspondingMetric.set('value', value);
                }

                return value;
            }
        }),

        updateCaptureRequest: (0, _emberApiActions.memberAction)({ path: 'update-scrape', type: 'put' })
    });
});
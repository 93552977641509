define('tm-common/components/x-preview-document', ['exports', 'tm-common/templates/components/x-preview-document', 'ember-lifeline/mixins/run'], function (exports, _xPreviewDocument, _run) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_run.default, {
        ajax: Ember.inject.service(),
        metrics: Ember.inject.service(),
        /**
         * used to get configuration from the application
         *
         * @property
         */
        ENV: Ember.computed(function () {
            var applicationConfig = Ember.getOwner(this).resolveRegistration('config:environment');
            return applicationConfig;
        }),

        /**
         * @service
         *
         * to show notifications
         */
        notify: Ember.inject.service(),

        /**
         * @service
         *
         * to get the token from the session
         */
        session: Ember.inject.service(),

        /**
         * @property
         *
         * it specifies how the component should be renderer.
         */
        layout: _xPreviewDocument.default,

        /**
         * @property
         *
         * @public
         *
         * specify the model used to display the preview
         */
        document: null,

        /**
         * @property
         *
         * define the link to go back to
         */
        backLink: null,

        /**
         * @property
         *
         * to show a loading screen while loads the preview
         */
        isLoading: true,

        /**
         * @property
         *
         * show an error message from the preview call
         */
        errorMessage: null,

        /**
         * @property
         *
         * don't use box classes (for portal)
         */
        noBox: false,

        /**
         * @method
         *
         * initializer method for getting information about the preview
         */
        initPreview: Ember.computed('document', function () {
            var _this = this;

            var document = this.get('document');
            this.set('isLoading', true);
            if (Ember.isPresent(document)) {
                var self = this;
                var documentId = this.get('document.id');
                Ember.RSVP.hash({
                    preview_info: this.get('ajax').request('documents/' + documentId + '/preview')
                }).then(function (response) {
                    var waitTime = 0;
                    if (response.preview_info.wait) {
                        waitTime = 1000;
                    }
                    _this.runTask(function () {
                        // Giving the backend 2 seconds to generate the first page
                        self.set('totalPages', response.preview_info.total_pages);
                        self.set('typeOfPreview', response.preview_info.type);
                        self.set('currentPage', 1);
                        self.set('documentId', documentId);
                        self.set('isLoading', false);

                        Ember.get(this, 'metrics').trackEvent({
                            category: 'Documents',
                            action: 'preview',
                            label: document.get('name')
                        });
                        this.runTask(function () {
                            Ember.$('#pdf-js-viewer').contents().find('#print').hide();
                        }, 500);
                    }, waitTime);
                }, function (error) {
                    self.set('isLoading', false);
                    self.set('errorMessage', error.errors[0].additional_info.title);
                });
            }
        }),

        /**
         * the current page being displayed
         */
        currentPage: 1,

        /**
         * total number of pages in the document
         */
        totalPages: null,

        /**
         * indicates what kind of preview we are going to show
         * it comes back from the API to say if the document can be previewed
         * as images or as an iframe with an extract
         *
         * Options: preview, extract, download, image
         */
        typeOfPreview: null,

        /**
         * the URL to load the preview image for a particular page
         */
        previewURL: Ember.computed('currentPage', 'documentId', function () {
            var currentPage = this.get('currentPage');
            var documentId = this.get('documentId');
            var ENV = this.get('ENV');
            return '' + ENV.APP.documentDownloadURL + documentId + '/preview/' + currentPage + '?token=' + this.get('session.data.authenticated.token');
        }),

        /**
         * the URL to download the file
         */
        downloadURL: Ember.computed('documentId', function () {
            var self = this;
            var ENV = this.get('ENV');
            this.runTask(function () {
                Ember.$('#viewerContainer').css({
                    height: self.get('noBox') === true ? '65vh' : '87vh'
                });
            }, 1000);
            return '' + ENV.APP.documentDownloadURL + this.get('documentId') + '/download?token=' + this.get('session.data.authenticated.token') + '&for_preview=true';
        }),

        encodedURL: Ember.computed('downloadURL', function () {
            return encodeURIComponent(this.get('downloadURL'));
        }),

        /**
         * the src object to pass to ivy videojs component
         */
        videoSrc: Ember.computed('documentId', function () {
            return {
                src: this.get('downloadURL'),
                type: this.get('document.type')
            };
        }),

        /**
         * Check if the preview is paginated
         * For now only pdfs are paginated
         *
         * @method shouldShowPaginateControls
         * @return boolean
         */
        shouldShowPaginateControls: false,

        /**
         * Check if the preview is just an image
         *
         * @method shouldShowAsImage
         * @return boolean
         */
        shouldShowAsImage: Ember.computed.equal('typeOfPreview', 'image'),

        /**
         * Check if the preview is a pdf
         *
         * @method shouldShowAsPDF
         * @return boolean
         */
        shouldShowAsPDF: Ember.computed.equal('typeOfPreview', 'preview'),

        /**
         * Check if the preview can be shown as an iframe
         *
         * @method shouldShowAsIframe
         * @return boolean
         */
        shouldShowAsIframe: Ember.computed.equal('typeOfPreview', 'extract'),

        /**
         * Check if the preview can't be generated, so it should show
         * a download link
         *
         * @method shouldShowAsDownload
         * @return boolean
         */
        shouldShowAsDownload: Ember.computed.equal('typeOfPreview', 'download'),

        /**
         * Check if can be played as video
         *
         * @method shouldShowAsVideo
         * @return boolean
         */
        shouldShowAsVideo: Ember.computed.equal('typeOfPreview', 'video'),

        /**
         * Check if file deleted
         *
         * @method noShow
         * @return boolean
         */
        noShow: Ember.computed.equal('typeOfPreview', 'missing'),

        actions: {
            prev: function prev() {
                var currentPage = this.get('currentPage') - 1;

                if (currentPage >= 1) {
                    this.set('currentPage', currentPage);
                } else {
                    // first page reached
                    this.get('notify').info('First page reached.');
                }
            },

            next: function next() {
                var currentPage = this.get('currentPage') + 1;
                var totalPages = this.get('totalPages');

                if (currentPage <= totalPages) {
                    this.set('currentPage', currentPage);
                } else {
                    // last page reached
                    this.get('notify').info('Last page reached.');
                }
            },

            first: function first() {
                this.set('currentPage', 1);
            },

            last: function last() {
                var totalPages = this.get('totalPages');
                this.set('currentPage', totalPages);
            }
        }
    });
});
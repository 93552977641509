define('ember-cli-table-pagination/mixins/table-pager/column', ['exports', 'ember-cli-table-pagination/utils/operators-list'], function (exports, _operatorsList) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    // the friendly name
    displayName: null,

    // the model property
    fieldName: null,

    // list this field in the search component?
    enableSearch: true,

    // list this field in the main listing?
    enableDisplay: true,

    // order in which to display the fields
    order: 0,

    // show the quick filter or not
    showingFilter: false,

    // value on the filter for this column
    filterValue: null,

    // To make a column not searchable/sortable
    disableServerInteractions: false,

    // developer supplied value that maps to API field
    // this value is used in preference to fieldName when the client needs to interact with this api field
    apiName: null,

    // Component used to display the cell in the table
    cellComponent: null,

    // Component used to capture input from the advanced filter
    advFilterComponent: null,

    // calculate to pull either the apiName or fieldName
    apiInteractionName: Ember.computed('fieldName', 'apiName', function () {
      if (Ember.isPresent(this.get('apiName'))) {
        return this.get('apiName');
      } else if (Ember.isPresent(this.get('fieldName'))) {
        return Ember.String.underscore(this.get('fieldName'));
      }
    }),

    width: undefined,

    align: 'left',

    usesPowerSelect: false,
    displayPath: null,
    valuePath: null,

    // Advanced Filter fields
    acceptedOperators: [],
    advFilterOperator: Ember.computed('acceptedOperators', function () {
      return (0, _operatorsList.getOperator)(this.get('acceptedOperators'));
    }),
    advFilterValue: undefined,
    advFilterValue2: undefined,

    observeHeader: Ember.observer('filterValue', function () {
      this.set('advFilterValue', this.get('filterValue'));
    }),

    clearFilter: function clearFilter() {
      this.set('filterValue', null);
      this.set('advFilterValue', null);
      this.set('advFilterValue2', null);
      this.set('advFilterOperator', (0, _operatorsList.getOperator)(this.get('acceptedOperators')));
    }
  });
});
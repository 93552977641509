define('tm-common/models/case-workflow', ['exports', 'ember-data', 'moment', 'ember-api-actions'], function (exports, _emberData, _moment, _emberApiActions) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        hasMany = _emberData.default.hasMany;
    exports.default = Model.extend({
        matterId: attr('number'),
        matterWorkflowId: attr('number'),
        matterWorkflowAssigneeId: attr('number'),
        matterCode: attr('string'),
        matterName: attr('string'),
        matterProgressStatus: attr('string'),
        matterTypeId: attr('number'),
        matterCollectionId: attr('number'),
        workflowId: attr('number'),
        enabled: attr('number'),
        name: attr('string'),
        assignedToId: attr('number'),
        assignedTo: attr('string'),
        assignedToFullName: attr('string'),
        assignedOn: attr('estdate'),
        completedOn: attr('estdate'),
        status: attr('string'),
        comment: attr('string'),
        hours: attr('number'),
        percentage: attr('number'),
        startOn: attr('string'),
        deadline: attr('string'),
        calcDeadline: attr('string'),
        hardDeadline: attr('number'),
        calcNextDue: attr('string'),
        various: attr('boolean'),
        updatedOn: attr('estdate'),
        createdOn: attr('estdate'),
        caseOwnerIds: attr(),
        weight: attr('number'),
        lastEntry: attr('string'),
        totalHours: attr('number'),
        onScheduler: attr('boolean'),
        onSchedulerSince: attr('string'),
        lastScheduled: attr('string'),
        numberOfAssignees: attr('number'),
        buffer: attr('number'),
        lastReportDate: attr('string'),

        workflowField: Ember.computed('name', function () {
            return Ember.String.underscore(this.get('name'));
        }),

        isCustom: Ember.computed('id', function () {
            return this.get('id').split('-').length === 4;
        }),

        events: hasMany('case-workflow-event', {
            async: false
        }),

        dotColor: Ember.computed('deadline', function () {
            if (Ember.isEmpty(this.get('deadline'))) {
                return 'white';
            }
            var today = (0, _moment.default)();
            var diff = (0, _moment.default)(this.get('deadline')).diff(today, 'days');
            if (diff < 0) {
                return 'gray';
            } else if (diff <= 1) {
                return 'red';
            } else if (diff <= 7) {
                return 'orange';
            } else {
                return 'green';
            }
        }),

        multipleHours: Ember.computed('various', 'hours', 'numberOfAssignees', function () {
            if (this.get('various')) {
                return this.get('hours') * this.get('numberOfAssignees');
            }

            return this.get('hours');
        }),

        createMatterWorkflow: (0, _emberApiActions.memberAction)({
            path: 'create',
            type: 'post'
        }),

        updateAllMatterWorkflow: (0, _emberApiActions.memberAction)({
            path: 'mass',
            type: 'put'
        }),

        updateWeights: (0, _emberApiActions.collectionAction)({
            path: 'update-weights',
            type: 'put'
        })
    });
});
define('tm-common/components/field-is-visible', ['exports', 'tm-common/templates/components/field-is-visible'], function (exports, _fieldIsVisible) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _fieldIsVisible.default,
        tagName: ''
    });
});